import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../styles/MobileNavbar.css";
// import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";

const Nav = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="Navbara">
        <div className="leftside">
        <Link to='/'><img
            src={require("../assets/headlogo.png")}
            style={{ width: "100px" }}
          /></Link> 
        </div>
        <div className="rightside">
          <div className="toggle" onClick={() => setShow(!show)}>
            <DensityMediumIcon style={{ color: "gray" }} />
          </div>
        </div>
      </div>

      {show && (
        <div>
          <div className="option">
            <Link to={"/"} style={{textDecoration:'none',color:'black'}}>HOME</Link>
          </div>
          <div className="option">
            <HashLink to={"#Categories"} style={{textDecoration:'none',color:'black'}}>CATEGORIES</HashLink>
          </div>
          <div className="option">
            {" "}
            <Link to={"/Story"} style={{textDecoration:'none',color:'black'}}>ABOUT</Link>
          </div>
          <div className="option">
            <Link to={"/ContactUs1"} style={{textDecoration:'none',color:'black'}}>CONTACT</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Nav;
