import React from "react";
import "../styles/OurBackground.css";
import Newslatter from "../Components/Newslatter";

const OurBackground = () => {
  return (
    <div>
      <div className="bannersec">
        <div className="headerseccont">
          <h3>OUR BACKGROUND</h3>
          <p className="header_acount-paragh">
            Expertise in all kinds of ac, dc, PM and SRM motor drives, rotating
            and linear motor drives
          </p>
        </div>
        <div className="container">
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="col-md-3 col-sm-6 herocard">
              <img
                src={require("../assets/heromotor.png")}
                className="heroimg"
              ></img>
              <h3>AC Motors</h3>
            </div>
            <div className="col-md-3  col-sm-6 herocard">
              <img src={require("../assets/m2.png")} className="heroimg"></img>
              <h3>DC Motors</h3>
            </div>
            <div className="col-md-3  col-sm-6 herocard">
              <img src={require("../assets/m1.png")} className="heroimg"></img>
              <h3>PM Motors</h3>
            </div>
            <div className="col-md-3  col-sm-6 herocard">
              <img src={require("../assets/m3.png")} className="heroimg"></img>
              <h3>SRM Motors</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container author" style={{ textAlign: "center" }}>
        <div className="row">
          <h3 className="authorhead">Authored books</h3>
        </div>
        <div className="row booksec">
          <div className="col-md-2 col-sm-6">
            <img src={require("../assets/book1.png")} className="books"></img>
          </div>
          <div className="col-md-2  col-sm-6">
            <img src={require("../assets/book2.png")} className="books"></img>
          </div>
          <div className="col-md-2  col-sm-6">
            <img src={require("../assets/book3.png")} className="books"></img>
          </div>
          <div className="col-md-2  col-sm-6">
            <img src={require("../assets/book4.png")} className="books"></img>
          </div>
          <div className="col-md-2  col-sm-6">
            <img src={require("../assets/book5.png")} className="books"></img>
          </div>
          <div className="col-md-2  col-sm-6">
            <img src={require("../assets/book2.png")} className="books"></img>
          </div>
        </div>
      </div>
      <div className="Summary" style={{ textAlign: "center" }}>
        <div className="container">
          <div className="row">
            <h3 className="sumhead">SUMMARY</h3>
            <p className="sumpara">
              {" "}
              Based on our unique and patented Trapezoidal Stator topology, our
              electric motor provides superior power and performance – and is
              less than half the size and weight of state-of-the-art RFPM
              motors.
            </p>
          </div>
          <div className="row secsec">
            <div className="col">
              <h3 className="ul1 technology">TECHNOLOGY</h3>
              <p className="technolog-paragraph">IE is developing a number of innovative and original</p>
              <ul className="ul1">
                <li> Electric motors,</li>
                <li>Electronic power converters to energize the motor,</li>
                <li>
                  Controllers to coordinate the working of the electric motor,
                  power converter, and battery, and
                </li>
                <li>
                  In-built chargers for battery as part of the motor drive
                  system.
                </li>
              </ul>
            </div>
            <div className="col imgr">
              <img
                src={require("../assets/rside.png")}
                style={{ width: "400px", height: "300px" }}
              ></img>
            </div>
          </div>
        </div>
      </div>
      <Newslatter />
    </div>
  );
};

export default OurBackground;
