import React from "react";
import "../styles/Contact.css";

// Contact Icons
import LocationIcons from "../assets/locationIcons.png";
import Email from "../assets/EmailIcon.png";
import Example from "../assets/Example_icons.png";



const ContactUs1 = () => {

  return (
    <>
      <section className="contact-us">
        <div className="contact-us-section">
          <div className="contact-us-head">
            <h1>Contact us</h1>
            <p style={{textAlign:'center',color:'white',fontWeight:'bold'}}>Contact us for your specific high volume applications of motor drives</p>
          </div>
        </div>
      </section>
      <section className="contact-form1 row">
        <div className="GetToucuh col">
          <h2 className="get-in-touch">Get in Touch</h2>
       <div className="inpt1">
<input type="text" placeholder="Enter your name"  className="int3"/>
       </div>
       <div className="inpt1">
<input type="email" placeholder="Enter your email"  className="int3"/>
       </div>
       <div className="inpt11">
<input type="text" placeholder="Enter your comment"  className="int3"/>
       </div>
       <button className="sentbtn">
       Send message
       </button>
        </div>
        <div className="Contact-us col">
        <h2 className="get-in-touch ">Contact us</h2>
         <p className="my-3 center" style={{color:'grey'}}>We’d love to hear from you. Please fill out this form.</p>
         <div className="row my-5">
            <div className="col-md-3 center">
<img src={require('../assets/locationIcons.png')}/>
            </div>
            <div className="col-md-6">
<p style={{color:'grey'}} className="center" >33 Sobha West Hill,
Ajjanur, Coimbatore 641007</p>
</div>
         </div>
         <div className="row my-5">
            <div className="col-md-3 center">
<img src={require('../assets/EmailIcon.png')} />
            </div>
            <div className="col-md-3">
<p style={{color:'grey'}} className="center">marketing@ixorelectric.com</p>
</div>
         </div>
        
        </div>
      </section>
    </>
  );
};

export default ContactUs1;
