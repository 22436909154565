import React from "react";
import "../styles/MainCategories.css";

// Categories Cards Images
import hubMotor from "../assets/2-hub-motor.png";
import midMotor from "../assets/3-mid-motor.png";
// import RototWbrush from "../assets/Rotot with Brush.png";
// import StatorWBrush from "../assets/Stator with steel.png";

const MainCategories = () => {
  // Categoreis-Card
  const CategoriesCard = ({ img, Tittle }) => {
    return (
      <div className="categories-card">
        <div className="categories-card-img">
          <img src={img} alt="" />
          <div className="categoris-card-tittle">
            <h3>{Tittle}</h3>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section id="Categories" className="main-categories">
        <div className="Merasurment-section"></div>
        <div className="categoreies-header">
          <h2 className="categories-head">
            Product <span>Categories</span>
          </h2>
        </div>
        <div className="categories-items">
          <CategoriesCard img={hubMotor} Tittle={"Hub Motor"} />
          <CategoriesCard img={midMotor} Tittle={"Chassis Motor"} />
          {/* <CategoriesCard img={RototWbrush} Tittle={"Rotor with brush"} />
          <CategoriesCard img={StatorWBrush} Tittle={"Rotor with brush"} /> */}
        </div>
       
      </section>
    </>
  );
};

export default MainCategories;
