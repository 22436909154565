import React from "react";
import "../styles/Newslatter.css";

const Newslatter = () => {
  return (
    <div className="newslatter" style={{ padding: "5px" }}>
      <div className="centercont">
        <div className="left">
          <h5>Newsletter</h5>
          <p>Register here if you wish to receive quarterly tech newsletters</p>
        </div>
        <div className="right">
          <div className="inputsec">
            <img
              src={require("../assets/msg.png")}
              style={{ height: "20px", marginTop: "15px" }}
            ></img>
            <input
              type="text"
              placeholder="Enter your email"
              className="inpt"
            />
            <div className="submitbutton">Submit</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newslatter;
