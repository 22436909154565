// Import Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ContactUs from "./Components/ContactUs";
import ContactUs2 from "./Components/ContactUs2";
import ContactUs3 from "./Components/ContactUs3";
import ContactUs4 from "./Components/ContactUs4";
import Header from "./Components/Header";
import HeroSection from "./Components/HeroSection";
import MobileHeader from "./Components/MobileNavbar";
import Footer from "./Components/Footer";
import AboutUs from "./Components/OurBackground";
import Story from "./Components/Story";
import ContactUs1 from "./Components/Contact1";

function App() {
  return (
    <>
      <Router>
        <Header />
        <MobileHeader />

        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/contact" element={<ContactUs2 />} />
          <Route path="/contact" element={<ContactUs3 />} />
          <Route path="/contact" element={<ContactUs4 />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/Story" element={<Story/>} />
          <Route path="/ContactUs1" element={<ContactUs1/>} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
