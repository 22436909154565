import React,{useState,useEffect} from "react";
import "../styles/Footer.css";
import { HashLink } from "react-router-hash-link";
import { Link, NavLink } from "react-router-dom";
const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    setIsActive(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    // Reset the active state after a short delay (you can adjust the delay time)
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <div>
      <div className="main">
        <div className="container cont-main">
          <div className="row">
            <img
              src={require("../assets/footerlogo.png")}
              className="footerlogo"
            ></img>
          </div>
          <div className="row">
            <p className="foopara">
             Ixor Electric is founded as a 'Make in india' Technology Innovation company to bring environment and sustainability friendly magnet free Swiched Reluctance Motor (SRM) to widespread application. The company is backed up with 40 years of R&D in the area of design methodologies, power converter topologies and controller algorithms in the SRM drive systems. Ixor has lines up several patents in SRM drive and will be mass manufacturing them for Home Appliance, Automobiles and Pumps.
             
            </p>
          </div>
          <div className="row foopara">
            
            <div className="col-md-6 icondiv">
              <div className="icon">
                <img
                  src={require("../assets/phone.png")}
                  className="footericons"
                ></img>
              </div>
              <div className="iconinfo">
                <span>Tel</span>
                <span style={{ color: "#A72529" }}>+91 422 358 3311</span>
              </div>
            </div>
            <div className="col-md-6  foopara">
              <div className="col icondiv">
                <div className="icon">
                  <img
                    src={require("../assets/mail.png")}
                    className="footericons"
                  ></img>
                </div>
                <div className="iconinfo">
                  <span>Mail</span>
                  <span style={{ color: "#A72529" }}>marketing@ixorelectric.com</span>
                </div>
              </div>
            </div>
            <div className="col-md-6  foopara">
              <div className="col icondiv">
                <div className="icon">
                  <img
                    src={require("../assets/location.png")}
                    className="footericons"
                  ></img>
                </div>
                <div className="iconinfo">
                  <span>Address</span>
                  <span style={{ color: "#A72529" }}>
                    33 Sobha West Hill,<br/>
                    Ajjanur, Coimbatore 641007
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6  foopara">
              <div className="col icondiv">
                <div className="icon">
                  <img
                    src={require("../assets/printer.png")}
                    className="footericons"
                  ></img>
                </div>
                <div className="iconinfo">
                  <span>Fax</span>
                  <span style={{ color: "#A72529" }}>+91 422 358 3311</span>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        <div className="container foobottom">
          <div className="half">
        <NavLink to="/" style={{textDecoration:'none'}}><span     className={`scroll-to-top-button ${isActive ? 'active' : ''}`}
          onClick={scrollToTop}>Home</span></NavLink>  
        
          </div>
          <div className="half1">© All Rights Reserved</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
