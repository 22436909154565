import React from "react";
import "../styles/Product.css";

const Product = () => {
  return (
    <div>
      <div className="main-product">
        <div className="container">
          <div className="row">
            <h2 className="Product-head-01">
              Our Specialization in{" "}
              <span className="underline">Product and Applications</span>
            </h2>
          </div>
          <div className="row">
            <p className="ppara">
              {" "}
              Based on our novel motor and power converter toplogies, Ixor csn offer motor solutions for several application domains with very little modifications in the design and production methodologies.
            </p>
          </div>
          <div className="row" style={{textAlign:'center'}}>
            <div className="col">
              <img
                src={require("../assets/ricksha.png")}
                className="cardimg"
              ></img>
              <p className="cardhead">EV: 2W and 3W</p>
            </div>
            <div className="col">
              <img
                src={require("../assets/four EV.png")}
                className="cardimg"
              ></img>
              <p className="cardhead">EV: Four Wheelers</p>
            </div>
            <div className="col">
              <img
                src={require("../assets/electronic.png")}
                className="cardimg"
              ></img>
              <p className="cardhead">Electric Fans</p>
            </div>
            <div className="col">
              <img
                src={require("../assets/kitchen.png")}
                className="cardimg"
              ></img>                                                                         
              <p className="cardhead">Home Appliance </p>
            </div>
            <div className="col">
              <img
                src={require("../assets/water_pump-removebg-preview.png")}
                className="cardimg"
              ></img>
              <p className="cardhead">Water Pumps</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
