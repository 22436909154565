import React from "react";
import "../styles/HeroSection.css";

import { HashLink } from "react-router-hash-link";
// Components Import
import MainCategories from "../Components/MainCategories";
import NewProduct from "../Components/NEWPRODUCTS";
import Speacilize from "../Components/Product";
import Newslatter from "./Newslatter";
// Hero Section Images
import CrouselImg1 from "../assets/seemotor.png";
import Back from "../assets/back.png";
import Front from "../assets/Front.png";

const HeroSection = () => {
  return (
    <>
      <div className="section-Hero" id="home">
        <div className="hero-section-content-main">
          <div className="hero-section-content-Text">
            <div className="Hero-Section-head">
              <span>Design and Manufacture of</span>
              <h1 className="Main-Head">
                Switched Reluctance
                <br /> Motors
              </h1>
              <p className="HeroPara">
                {" "}
                Magnet free motors manufactured with parts entirely sourced in
                india controlled by properietary power electronic converter and
                control algorithms guarantees high reliability and efficiency,
                offering cost effective solution to multiple application
                domains.
              </p>
              {/* <div className="Hero-button-main">
                <button className="shop-now">Shop Now</button>
                <button className="Contact-Us">Contact Us</button>
              </div> */}
            </div>
          </div>
          <div className="hero-section-img-carousel">
            <div className="hero-section-img">
              <img src={CrouselImg1} alt="" />
            </div>
          </div>
        </div>
      </div>
      <MainCategories />

      <Speacilize />
      <Newslatter />

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          height: "100px",
        }}
      >
       
      </div>
    </>
  );
};

export default HeroSection;
