import React from "react";
import "../styles/Header.css";

// Logo Import
import Logo from "../assets/MainLogo.png";
import { useState } from "react";
import SearchIcon from "../assets/SearchIcon.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const [activeItem, setActiveItem] = useState("home"); // Initialize with the default active item

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  return (
    <>
      <div className="hEADER-MAIN">
        <div className="top-border"></div>
        <nav>
          <div className="navbar-items">
            <div className="nav-bar-tabs">
              <div className="navv-bar-logo-section">
                <Link>
                  {" "}
                  <img src={Logo} alt="" />
                </Link>
              </div>
              <div className="navbar-navli-links">
                <ul className="navigation">
                  <li>
                    <Link
                      className={activeItem === "home" ? "active" : ""}
                      onClick={() => handleItemClick("home")}
                      to={"/"}
                    >
                      HOME
                    </Link>
                  </li>
                  <li>
                    <HashLink
                      className={activeItem === "CATEGORIES" ? "active" : ""}
                      onClick={() => handleItemClick("CATEGORIES")}
                      to={"#Categories"}
                    >
                      PRODUCTS
                    </HashLink>
                  </li>
                  <li>
                    <Link
                      className={activeItem === "about" ? "active" : ""}
                      onClick={() => handleItemClick("about")}
                      to={"/Story"}
                    >
                      ABOUT US
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={activeItem === "contact" ? "active" : ""}
                      onClick={() => handleItemClick("contact")}
                      to={"/ContactUs1"}
                    >
                      CONTACT
                    </Link>
                  </li>
                  <li>
                    
                  </li>
                  {/* <li>
                    <Link
                      className={activeItem === "contact" ? "active" : ""}
                      onClick={() => handleItemClick("contact")}
                      to={"/contact"}
                    >
                      CONTACT
                    </Link>
                  </li> */}
                </ul>
              </div>
             
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
