import { Padding } from '@mui/icons-material'
import React from 'react'
import "../styles/Style.css";

const Story = () => {
  return (
    <div>
<div className='container'>
<div style={{
    height:'100%',
    backgroundColor:"#A72529",
    width:'100%' ,
    color:'white',
   textAlign:'center',
    fontSize:'40px',
    fontWeight:'600',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    paddingTop:'20px',
    paddingBottom:'20px'

}}   >The Ixor Story</div>
<div className='container ext' style={{
   height:'100px',
  paddingTop:'30px',

   width:'100%' ,
   color:'black',
   paddingBottom:'30px',
   fontSize:'20px',
   fontWeight:'400',
 
    }}>
An Indian Start-up that is on a mission to introduce SRM (Switched Reluctance Motors) products across various application domains for Indian and global markets-with emphasis on Make in India and Source in India.

</div>

<div className='row clas' >
<div className='col-md-6 col-sm-12' style={{display:'flex' ,alignItems:'center',justifyContent:'center'}} > 
<img
                  src={require("../assets/kr.png")}
                  style={{height:'80%',width:'70%'}}
                ></img>

</div>
<div className='col-md-6 col-sm-12' 
style={{
padding:'30px',
paddingTop:'50px',
paddingBottom:'50px',
backgroundColor:'rgb(249, 246, 246)',
display:'flex' ,alignItems:'left',justifyContent:'center',flexDirection:'column'

}}
> 
<span style={{fontSize:'20px',fontWeight:'500'}}>
Prof. Krishnan Ramu<br/>
IEEE Life Fellow

</span>
<br/>
Prof. (Emeritus) Krishnan Ramu, Life Fellow IEEE,prolific inventor (28 US and 3 Japanese patents), specialized in machines, power converters and control systems; founder of two startups (one funded exclusively by khosla Ventures) with successful exits (one to Regal Beloit Corp - a $4B company in(USA); 10 years industrial and more than 36 years academic experience; author of 6 books in motor drives (only one to write all aspects of the motor drive systems in the world), consultant to major companies. 



</div>
</div>
<div className='row'>
<div className='col-md-6 col-sm-12' 
style={{
padding:'30px',
paddingTop:'50px',
paddingBottom:'50px',
backgroundColor:'rgb(249, 246, 246)',
display:'flex' ,alignItems:'left',justifyContent:'center',flexDirection:'column'
}}
> 
<span style={{fontSize:'20px',fontWeight:'500'}}>
Prabhakar Marur, Ph.D
    </span><br/>
Dr. Marur has served as senior executive in General Motors, Lam Research, Pricol. He has an industrial research and leadership experience that spans three decades. He has grown and managed teams of sizes ranging from 250 engineers to 700 engineers in different domains such as aerospace, automotive and semiconductor equipment. He eamed his Ph.D. degree in Mechanical Engineering from Auburn University, USA, and has published 35+ intemational journal papers and has been granted 10 +USA/international patents.







</div>
<div className='col-md-6 col-sm-12' style={{display:'flex' ,alignItems:'center',justifyContent:'center'}} > 
<img
                  src={require("../assets/secondperson.jpg")}
                  style={{height:'80%',width:'70%',textAlign:'center'}}
                ></img>

</div>

</div>
</div>

    </div>
  )
}

export default Story