import React from "react";
import "../styles/Contact.css";

// Contact Icons
import LocationIcons from "../assets/locationIcons.png";
import Email from "../assets/EmailIcon.png";
import Example from "../assets/Example_icons.png";



const ContactUs = () => {

  return (
    <>
      <section className="contact-us">
        <div className="contact-us-section">
          <div className="contact-us-head">
            <h1>NEW PRODUCTS</h1>
          </div>
        </div>
      </section>
      <section className="contact-form">
        <div className="GetToucuh">
          <h2 className="get-in-touch">Rotor with brush</h2>
          <p className="pclass" style={{ fontSize: "20px" }}>
            IE is developing a number of innovative and original
          </p>

          <ul style={{ fontSize: "20px" }}>
            <li> Electric motors,</li>
            <li>Electronic power converters to energize the motor,</li>
            <li>
              Controllers to coordinate the working of the electric motor, power
              converter, and battery, and
            </li>
            <li>
              In-built chargers for battery as part of the motor drive system.
            </li>
          </ul>
        </div>
        <div className="Contact-us">
         
          <img src={require("../assets/RotorWbrush-03.png")} className="moo" />
        </div>
      </section>
    </>
  );
};

export default ContactUs;
